import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { contextArr } from '../db';

import Pane from '../components/Menu/Pane';
import Tabs from '../components/Menu/Tabs';

import Dashboard from '../components/_Dashboard/Dashboard';
import Calories from '../components/_Calories/Calories';
import IdealWeight from '../components/_IdealWeight/IdealWeight';
import FatPercentage from '../components/_FatPercentage/FatPercentage';
import Setting from '../components/_Setting/Setting';

export default function Calc() {
	const location = useLocation();
	const [lang, setLang] = useState('en');
	const [checkedLang, setCheckedLang] = useState(false);
	const [font, setFont] = useState("'Montserrat', sans-serif");
	const [checkedFont, setCheckedFont] = useState(false);
	const [darkMode, setDarkMode] = useState(!getInitialMode());

	for (let key in contextArr) {
		let elem = document.querySelector('.lng-' + key);
		if (elem) {
			elem.innerHTML = contextArr[key][lang];
		}
	}

	function getInitialMode() {
		const isReturningUser = 'dark' in localStorage;
		const savedMode = JSON.parse(localStorage.getItem('dark'));
		const userPrefersDark = getPrefColorScheme();

		if (!isReturningUser) {
			return savedMode;
		} else if (userPrefersDark) {
			return true;
		} else {
			return false;
		}
	}

	function getPrefColorScheme() {
		if (!window.matchMedia) return;
		return window.matchMedia('(prefers-color-scheme: dark)').matches;
	}

	useEffect(() => {
		window.location.href = location.pathname + '#' + lang;
	}, [location.pathname, lang]);

	useEffect(() => {
		localStorage.setItem('dark', JSON.stringify(darkMode));
	}, [darkMode]);

	return (
		<main
			id='main'
			style={{ fontFamily: font }}
			className={darkMode ? 'dark-mode' : 'light-mode'}>
			<div className='container df'>
				<Tabs>
					<Pane
						label='Dashboard'
						classNameI='fas fa-tachometer-alt'
						classNameSpan='lng-nav_item1'>
						<Dashboard lang={lang} />
					</Pane>
					<Pane
						label='Calorie rate'
						classNameI='fas fa-hamburger'
						classNameSpan='lng-nav_item2'>
						<Calories lang={lang} />
					</Pane>
					<Pane
						label='Ideal weight'
						classNameI='fas fa-weight'
						classNameSpan='lng-nav_item3'>
						<IdealWeight lang={lang} />
					</Pane>
					<Pane
						label='Fat percentage'
						classNameI='fas fa-calculator'
						classNameSpan='lng-nav_item4'>
						<FatPercentage lang={lang} />
					</Pane>
					<Pane
						label='Setting'
						classNameI='fas fa-tools'
						classNameSpan='lng-nav_item5'>
						<Setting
							lang={lang}
							setLang={setLang}
							checkedLang={checkedLang}
							setCheckedLang={setCheckedLang}
							darkMode={darkMode}
							setDarkMode={setDarkMode}
							font={font}
							setFont={setFont}
							checkedFont={checkedFont}
							setCheckedFont={setCheckedFont}
						/>
					</Pane>
				</Tabs>
			</div>
		</main>
	);
}
