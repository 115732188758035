import { CALC_ROUTE, LOGIN_ROUTE } from './consts';
import Login from '../_pages/Login';
import Calc from '../_pages/Calc';

export const publicRoutes = [
	{
		path: LOGIN_ROUTE,
		Component: Login,
	},
];

export const privateRoutes = [
	{
		path: CALC_ROUTE,
		Component: Calc,
	},
];
