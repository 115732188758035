import React from 'react';
import './Total.scss';

export default function Total({ ...props }) {
	if (props.total < 0) {
		props.total = 0;
	}

	return (
		<div id='total'>
			<h4 className='lng-result'>{props.title}</h4>
			<div className='wrapper df fww'>
				{props.children}
				<div className='total df'>
					<p className={props.classSubtitle}>{props.subtitle}</p>
					<div className='result'>
						<span>{props.total}</span> {props.units}
					</div>
				</div>
			</div>
		</div>
	);
}
