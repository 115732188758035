import React, { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './components/Auth/AppRouter';
import { Context } from '../index';
import { useAuthState } from 'react-firebase-hooks/auth';
import Loader from './components/Loader/Loader';
import './App.scss';

const App = () => {
	const { auth } = useContext(Context);
	const [user, loading, error] = useAuthState(auth);

	if (loading) {
		return <Loader />;
	}

	return (
		<BrowserRouter>
			<AppRouter />
		</BrowserRouter>
	);
};

export default App;
