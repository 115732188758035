import React from 'react';
import './Logo.scss';

const Logo = () => {
	return (
		<div id='logo' className='df'>
			<i className='fab fa-reacteurope'></i>
			<div className='wrapper'>
				<p>Perfectum</p>
				<ul className='df'>
					<li>c</li>
					<li>a</li>
					<li>l</li>
					<li>c</li>
					<li>u</li>
					<li>l</li>
					<li>a</li>
					<li>t</li>
					<li>o</li>
					<li>r</li>
				</ul>
			</div>
		</div>
	);
};

export default Logo;
