import React from 'react';

const Green = () => {
	return (
		<div className='wrapper' style={{ background: '#7ae770' }}>
			{window.location.hash === '#en'
				? 'Great % fat! % fat - optimal!'
				: 'Чудовий % жиру! % жиру - оптимальний!'}
		</div>
	);
};

export default Green;
