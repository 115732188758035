import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Burger from '../Burger/Burger';
import CloseBtn from '../CloseBtn/CloseBtn';
import LinkOut from '../LinkOut/LinkOut';
import Switcher from '../Switcher/Switcher';

import './Setting.scss';

export default function Setting({
	lang,
	setLang,
	checkedLang,
	setCheckedLang,
	font,
	setFont,
	checkedFont,
	setCheckedFont,
	darkMode,
	setDarkMode,
}) {
	const chooseLang = (e) => {
		setLang(e.target.checked ? (lang = 'ua') : (lang = 'en'));
		setCheckedLang(
			e.target.checked ? (checkedLang = true) : (checkedLang = false)
		);
	};

	const location = useLocation();

	useEffect(() => {
		window.location.href = location.pathname + '#' + lang;
	}, [location.pathname, lang]);

	const chooseFont = (e) => {
		setFont(
			e.target.checked
				? (font = "'Comfortaa', cursive")
				: (font = "'Montserrat', sans-serif")
		);
		setCheckedFont(
			e.target.checked ? (checkedFont = true) : (checkedFont = false)
		);
	};

	const [showBurger, setShowBurger] = useState(false);

	return (
		<section id='setting'>
			<div className='wrap df'>
				<div className='left df'>
					<div className='title'>
						<h1 className='lng-set_title'>Setting</h1>
						<Burger
							style={{ display: 'none' }}
							onClick={() => setShowBurger(!showBurger)}
						/>
					</div>
					<div className='wrapper'>
						<Switcher
							id='lang'
							title='Language'
							title_class='lng-set_subtitle1'
							label_lt='English'
							label_rt='Українська'
							value={lang}
							onClick={chooseLang}
							checked={checkedLang}
						/>
						<Switcher
							id='theme'
							title='Theme'
							title_class='lng-set_subtitle2'
							label_lt='Light'
							label_rt='Dark'
							value={darkMode}
							checked={darkMode}
							onClick={() => setDarkMode((prevMode) => !prevMode)}
						/>
						<Switcher
							id='font'
							title='Font'
							title_class='lng-set_subtitle3'
							label_lt='Montserrat'
							label_rt='Comfortaa'
							value={font}
							onClick={chooseFont}
							checked={checkedFont}
						/>
					</div>
					<div></div>
				</div>
				<div className={'right ' + (showBurger ? 'show' : '')}>
					<div className='version'>
						<h3>JS version</h3>
						<LinkOut
							href={'https://perfectum-js-app.adbbox.com/'}
							label={<i className='fab fa-js-square'></i>}
						/>
					</div>
					<div className='about'>
						<h4>
							About
							<br />
							development by
						</h4>
						<p>Bolotnikov Denys</p>
						<p>&</p>
						<LinkOut href={'http://adbbox.com'} label={'adbbox.com'} />
						<span>&#169; 2021 Ukraine, Kyiv</span>
					</div>
					<CloseBtn
						style={{ display: 'none' }}
						onClick={() => setShowBurger(!showBurger)}
					/>
				</div>
			</div>
		</section>
	);
}
