import React from 'react';

const Yellow = () => {
	return (
		<div className='wrapper' style={{ background: '#ebc82e' }}>
			{window.location.hash === '#en'
				? 'Small % fat! Watch your weight carefully!'
				: 'Малий % жиру! Уважно стежте за своєю вагою!'}
		</div>
	);
};

export default Yellow;
