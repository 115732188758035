import React from 'react';
import Yellow from './Yellow';
import Green from './Green';
import Red from './Red';
import './Recommendation.scss';

const RecommendationFatPercentage = (props) => {
	if (
		props.ranges[0].value === 23 &&
		props.ranges[1].value === 135 &&
		props.ranges[2].value === 55
	) {
		return (
			<div
				className='wrapper lng-recomend'
				style={{ background: '#d5e0ee', color: '#fff' }}>
				Small % fat! Watch your weight carefully!
			</div>
		);
	} else {
		if (props.sex === 'male') {
			if (props.age < 20) {
				if (props.total < 9) {
					return <Yellow />;
				} else if (props.total > 15) {
					return <Red />;
				} else {
					return <Green />;
				}
			} else if (props.age >= 20 && props.age <= 50) {
				if (props.total < 17) {
					return <Yellow />;
				} else if (props.total > 27) {
					return <Red />;
				} else {
					return <Green />;
				}
			} else {
				if (props.total < 19) {
					return <Yellow />;
				} else if (props.total > 23) {
					return <Red />;
				} else {
					return <Green />;
				}
			}
		} else {
			if (props.age < 20) {
				if (props.total < 14) {
					return <Yellow />;
				} else if (props.total > 21) {
					return <Red />;
				} else {
					return <Green />;
				}
			} else if (props.age >= 20 && props.age <= 50) {
				if (props.total < 17) {
					return <Yellow />;
				} else if (props.total > 27) {
					return <Red />;
				} else {
					return <Green />;
				}
			} else {
				if (props.total < 20) {
					return <Yellow />;
				} else if (props.total > 30) {
					return <Red />;
				} else {
					return <Green />;
				}
			}
		}
	}
};

export default RecommendationFatPercentage;
