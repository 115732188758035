import React from 'react';
import './Etalon.scss';

export default function Etalon({ ...props }) {
	const etalon_men =
		Math.round((props.height / 100) ** 2 * 18.5) +
		'-' +
		Math.round((props.height / 100) ** 2 * 25);
	const etalon_women =
		Math.round((props.height / 100) ** 2 * 18.5 * 0.95) +
		'-' +
		Math.round((props.height / 100) ** 2 * 25 * 0.95);

	return (
		<div id='etalon' className='range'>
			<h5 className='lng-etalon'>Optimal weight (kg)</h5>
			<div className='output'>
				{props.sex === 'male' ? etalon_men : etalon_women}
			</div>
		</div>
	);
}
