import React from 'react';

import './Burger.scss';

export default function Burger(props) {
	return (
		<button className='burger' style={props.style} onClick={props.onClick}>
			<i className=' fas fa-th-list'></i>
		</button>
	);
}
