import React from 'react';
import './Switcher.scss';

export default function Switcher({ ...props }) {
	return (
		<div className='switcher'>
			<h3 className={props.title_class}>{props.title}</h3>
			<div className='box'>
				<input
					id={props.id}
					type='checkbox'
					onClick={props.onClick}
					value={props.value}
					checked={props.checked}
					readOnly
				/>
				<label htmlFor={props.id}></label>
				<div className='labels df'>
					<p className='label-lt'>{props.label_lt}</p>
					<p className='label-rt'>{props.label_rt}</p>
				</div>
			</div>
		</div>
	);
}
