import React, { useEffect } from 'react';
import './RangeInput.scss';

export default function RangeInput({ label, ...props }) {
	const value = props.hasOwnProperty('value')
		? props.value
		: (props.min + props.max) / 2 || 0;

	const getRangeValue = (e) => props.onChange(props.id, +e.target.value);

	useEffect(() => {
		props.onChange(props.id, value);
	}, []);

	return (
		<div className='range'>
			<div
				className={
					value !== Math.floor((props.max + props.min) / 2)
						? 'output changed'
						: 'output'
				}>
				{value}
			</div>
			<input
				type='range'
				step='1'
				{...props}
				value={value}
				onChange={getRangeValue}
			/>
			<h4 className={props.lng}>{label}</h4>
		</div>
	);
}

// export default function RangeInput({ ...props }) {
// 	const [state, setState] = useState(Math.floor((props.max + props.min) / 2));

// 	return (
// 		<div className='range'>
// 			<div
// 				className={
// 					state !== Math.floor((props.max + props.min) / 2)
// 						? 'output changed'
// 						: 'output'
// 				}>
// 				{state}
// 			</div>
// 			<input
// 				type='range'
// 				{...props}
// 				step='1'
// 				defaultValue='50%'
// 				onChange={(e) => setState(e.target.value)}
// 			/>
// 			<h4 className={props.lng}>{props.label}</h4>
// 		</div>
// 	);
// }
