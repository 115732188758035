/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useContext } from 'react';
import { Context } from '../../../index';
import firebase from 'firebase/app';
import 'firebase/auth';
import './Navbar.scss';

const Navbar = () => {
	const { auth } = useContext(Context);
	const user = firebase.auth().currentUser;

	if (user !== null) {
	}

	return (
		<div id='nav-bar' className='df'>
			{user ? (
				<React.Fragment>
					<button
						className='btn-exit df'
						onClick={() => auth.signOut()}
						variant={'outlined'}>
						<i className='fas fa-sign-out-alt' />
						<p className='lng-nav_item6'>Exit account</p>
					</button>
					<img
						className='user-photo'
						src={user.photoURL}
						alt='userPhoto'
						title={user.displayName}
					/>
				</React.Fragment>
			) : (
				''
			)}
		</div>
	);
};

export default Navbar;
