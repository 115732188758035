import React, { useContext } from 'react';
import { Context } from '../../index';
import firebase from 'firebase';
import Logo from '../components/Logo/Logo';
import Author from '../components/Author/Author';

import '../_pages/Login.scss';
import Btn from 'App/components/Btn/Btn';

const Login = () => {
	const { auth } = useContext(Context);

	const login = async () => {
		const provider = new firebase.auth.GoogleAuthProvider();
		await auth.signInWithPopup(provider);
	};

	return (
		<div id='login'>
			<div className='container'>
				<div className='wrap df'>
					<Logo />
					<Btn className='btn' onClick={login}>
						<i className='fas fa-user-shield'></i> Google account
					</Btn>
				</div>
				<Author />
			</div>
		</div>
	);
};

export default Login;
