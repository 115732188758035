import React from 'react';

export default function Item(props) {
	const styles = {
		men: {
			height: props.men_h + '%',
		},
		women: {
			height: props.women_h + '%',
		},
	};

	return (
		<React.Fragment>
			<div className='lt df'>
				<p>100</p>
				<p>0</p>
			</div>
			<div className='md df'>
				<div className='level level-men' style={styles.men} />
				<div className='level level-women' style={styles.women} />
			</div>
			<div className='rt df'>
				<h3>{props.year}</h3>
				<div className='digital-item digital-men'>
					<div className='wrapper'>
						<p>{props.men_h}</p>
						<span className={props.lng_men}>men</span>
					</div>
				</div>
				<div className='digital-item digital-women'>
					<div className='wrapper'>
						<p>{props.women_h}</p>
						<span className={props.lng_women}>women</span>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
