/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import './Author.scss';

const Author = () => {
	return (
		<a id='author' target='_blank' href='https://adbbox.com'>
			<i className='far fa-copyright'></i> 2021 by adbbox.com
		</a>
	);
};

export default Author;
