import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Item from './Item';
import Burger from '../Burger/Burger';
import CloseBtn from '../CloseBtn/CloseBtn';

import './Dashboard.scss';

export default function Dashboard({ lang }) {
	const [toggleState, setToggleState] = useState(1);
	const [showBurger, setShowBurger] = useState(false);

	const toggleTab = (index) => {
		setToggleState(index);
	};

	const location = useLocation();

	const yearsContent = [
		{ id: 1, year: 1990, men_h: 65.61, women_h: 74.82 },
		{ id: 2, year: 1995, men_h: 61.22, women_h: 72.54 },
		{ id: 3, year: 2000, men_h: 62.11, women_h: 73.53 },
		{ id: 4, year: 2005, men_h: 62.23, women_h: 73.97 },
		{ id: 5, year: 2010, men_h: 65.28, women_h: 75.51 },
		{ id: 6, year: 2015, men_h: 66.37, women_h: 76.25 },
		{ id: 7, year: 2020, men_h: 66.92, women_h: 76.98 },
	];

	useEffect(() => {
		window.location.href = location.pathname + '#' + lang;
	}, [location.pathname, lang]);

	return (
		<section id='dashboard'>
			<div className='wrap df'>
				<div className='left df'>
					<div className='title'>
						<h1 className='lng-dash_title'>Dashboard</h1>
						<Burger
							style={{ display: 'none' }}
							onClick={() => setShowBurger(!showBurger)}
						/>
					</div>
					<div className='line-up df'>
						<div className='item'>
							<h3 className='lng-dash_subtitle'>Life expectancy</h3>
							<ul className='list'>
								{yearsContent.map((item) => (
									<li
										key={item.id}
										className={
											toggleState === item.id ? 'content active' : 'content'
										}>
										<Item
											key={item.id}
											men_h={item.men_h}
											women_h={item.women_h}
											year={item.year}
											lng_men={'lng-men' + item.id}
											lng_women={'lng-women' + item.id}
										/>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className='line-down'>
						<h2 className='lng-population'>Population of Ukraine</h2>
						<div className='wrapper df fww'>
							<div className='item'>
								<h3>1990</h3>
								<p>51 556 575</p>
								<span className='lng-residents1'>permanent residents</span>
							</div>
							<div className='item'>
								<h3>2020</h3>
								<p>41 732 811</p>
								<span className='lng-residents2'>permanent residents</span>
							</div>
						</div>
					</div>
				</div>
				<div className={'right ' + (showBurger ? 'show' : '')}>
					<div className='wrapper'>
						<h3 className='lng-dash_year'>Year</h3>
						<ul className='df tabs-dash'>
							{yearsContent.map((item) => (
								<li
									key={item.id}
									className={
										toggleState === item.id ? 'active tab-dash' : 'tab-dash'
									}
									onClick={() => toggleTab(item.id)}>
									{item.year}
								</li>
							))}
						</ul>
					</div>
					<CloseBtn
						style={{ display: 'none' }}
						onClick={() => setShowBurger(!showBurger)}
					/>
				</div>
			</div>
		</section>
	);
}
