import React, { Component } from 'react';

class Pane extends Component {
	render() {
		return (
			<div id='content'>
				<div className='tabs-content'>
					<div className='tab-pane'>{this.props.children}</div>
				</div>
			</div>
		);
	}
}

export default Pane;
