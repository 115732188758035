import React from 'react';
import './Recommendation.scss';

const RecommendationUa = (props) => {
	if (props.ranges[0].value === 176 && props.ranges[1].value === 90) {
		return (
			<div className='wrapper' style={{ background: '#d5e0ee', color: '#fff' }}>
				Зайва вага
			</div>
		);
	} else {
		if (props.total <= 16) {
			return (
				<div className='wrapper' style={{ background: '#e77070' }}>
					Сильний дефіцит маси тіла
				</div>
			);
		} else if (props.total > 16 && props.total <= 18.5) {
			return (
				<div className='wrapper' style={{ background: '#ebc82e' }}>
					Дефіцит маси тіла
				</div>
			);
		} else if (props.total > 18.5 && props.total <= 25) {
			return (
				<div className='wrapper' style={{ background: '#7ae770' }}>
					Оптимальна вага
				</div>
			);
		} else if (props.total > 25 && props.total <= 30) {
			return (
				<div className='wrapper' style={{ background: '#ebc82e' }}>
					Зайва вага
				</div>
			);
		} else if (props.total > 30 && props.total <= 35) {
			return (
				<div className='wrapper' style={{ background: '#ebc82e' }}>
					Ожиріння I ступеня
				</div>
			);
		} else if (props.total > 35 && props.total <= 40) {
			return (
				<div className='wrapper' style={{ background: '#e77070' }}>
					Ожиріння II ступеня
				</div>
			);
		} else if (props.total > 40) {
			return (
				<div className='wrapper' style={{ background: '#852d80' }}>
					Ожиріння III ступеня
				</div>
			);
		}
		return (
			<div className='wrapper' style={{ background: '#fff' }}>
				---
			</div>
		);
	}
};

export default RecommendationUa;
