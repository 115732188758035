/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Btn from '../Btn/Btn';
import RangeInput from '../RangeInput/RangeInput';
import Total from '../Total/Total';
import Etalon from '../Etalon/Etalon';
import RecommendationIdealWeightEn from '../Recommendation/RecommendationIdealWeightEn';
import RecommendationIdealWeightUa from '../Recommendation/RecommendationIdealWeightUa';
import CloseBtn from '../CloseBtn/CloseBtn';
import Table from '../Table/Table';
import Burger from '../Burger/Burger';

import './IdealWeight.scss';

export default function IdealWeight({ lang }) {
	const [gender, setGender] = useState([
		{
			id: 0,
			status: true,
			name: 'male',
			classes: 'lng-men',
		},
		{
			id: 1,
			status: false,
			name: 'female',
			classes: 'lng-women',
		},
	]);

	const [ranges, setRanges] = useState([
		{
			id: 'height',
			min: 152,
			max: 200,
			label: 'height (sm)',
			lng: 'lng-height',
			value: 176,
		},
		{
			id: 'weight',
			min: 50,
			max: 130,
			label: 'weight (kg)',
			lng: 'lng-weight',
			value: 90,
		},
	]);

	const [total, setTotal] = useState(0);

	const chooseGender = (id) => {
		setGender(
			gender.map((item) =>
				item.id === id ? { ...item, status: true } : { ...item, status: false }
			)
		);
	};

	const chooseRange = (id, value) => {
		setRanges(
			ranges.map((item) => (item.id === id ? { ...item, value } : item))
		);
	};

	const sex = gender.find((e) => e.status === true).name;
	const height = +ranges[0].value;
	const weight = +ranges[1].value;

	useEffect(() => {
		sex === 'male'
			? setTotal(Math.round(weight / (height / 100) ** 2))
			: setTotal(Math.round((weight / (height / 100) ** 2) * 0.95));
	}, [gender, ranges]);

	const location = useLocation();

	useEffect(() => {
		window.location.href = location.pathname + '#' + lang;
	}, [location.pathname, lang]);

	const [showBurger, setShowBurger] = useState(false);

	return (
		<section id='ideal-weight'>
			<div className='wrap df'>
				<div className='left df'>
					<div className='title'>
						<h1 className='lng-idw_title'>Ideal weight</h1>
						<Burger
							style={{ display: 'none' }}
							onClick={() => setShowBurger(!showBurger)}
						/>
					</div>
					<div className='wrapper'>
						<h3 className='lng-idw_subtitle'>Body Mass Index</h3>
						<div className='line-choose'>
							<h4 className='lng-data'>Physical data</h4>
							<div className='box df fww'>
								<div id='gender'>
									{gender.map((item) => (
										<Btn
											key={item.id}
											onClick={() => chooseGender(item.id)}
											className={
												item.status
													? `btn lng-${item.name} active`
													: `btn lng-${item.name}`
											}>
											{item.name}
										</Btn>
									))}
								</div>
								<div className='ranges df'>
									{ranges.map((item) => (
										<RangeInput
											key={item.id}
											onChange={chooseRange}
											value={item.value}
											{...item}
										/>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className='line-choose'>
						<Total
							title={'Result'}
							subtitle={'Current BMI:'}
							classSubtitle={'lng-current_bmi'}
							total={total}>
							<Etalon height={height} sex={sex} />
						</Total>
					</div>
					<div className='line-choose'>
						<div id='recommendation'>
							<h4 className='lng-recommendation'>Recommendation</h4>
							{window.location.hash === '#ua' ? (
								<RecommendationIdealWeightUa total={total} ranges={ranges} />
							) : (
								<RecommendationIdealWeightEn total={total} ranges={ranges} />
							)}
						</div>
					</div>
				</div>
				<div className={'right ' + (showBurger ? 'show' : '')}>
					<h3 className='lng-idw_table'>Table BMI</h3>
					<Table>
						<caption className='lng-idw_table_title gradient'>
							For Men & Women
						</caption>
						<thead>
							<tr>
								<th className='lng-idw_title1'>BMI</th>
								<th className='lng-idw_title2'>Description</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='lng-idw_tbl1 gradient'>less 16</td>
								<td className='lng-idw_tbl2 gradient'>Severe underweight</td>
							</tr>
							<tr>
								<td className='gradient'>16-18.5</td>
								<td className='lng-idw_tbl3 gradient'>Underweight</td>
							</tr>
							<tr>
								<td className='gradient'>18.5-25</td>
								<td className='lng-idw_tbl4 gradient'>Optimal weight</td>
							</tr>
							<tr>
								<td className='gradient'>25-30</td>
								<td className='lng-idw_tbl5 gradient'>Overweight</td>
							</tr>
							<tr>
								<td className='gradient'>30-35</td>
								<td className='lng-idw_tbl6 gradient'>Obesity I level</td>
							</tr>
							<tr>
								<td className='gradient'>35-40</td>
								<td className='lng-idw_tbl7 gradient'>Obesity II level</td>
							</tr>
							<tr>
								<td className='lng-idw_tbl8 gradient'>more 40</td>
								<td className='lng-idw_tbl9 gradient'>Obesity III level</td>
							</tr>
						</tbody>
					</Table>
					<CloseBtn
						style={{ display: 'none' }}
						onClick={() => setShowBurger(!showBurger)}
					/>
				</div>
			</div>
		</section>
	);
}
