import React from 'react';

import './CloseBtn.scss';

export default function CloseBtn(props) {
	return (
		<button className='btn-close' style={props.style} onClick={props.onClick}>
			<i className='far fa-times-circle'></i>
		</button>
	);
}
