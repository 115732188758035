export const contextArr = {
	nav_item1: {
		en: 'Dashboard',
		ua: 'Цифрова панель',
	},
	nav_item2: {
		en: 'Calorie rate',
		ua: 'Норма калорiй',
	},
	nav_item3: {
		en: 'Ideal weight',
		ua: 'Iдеальна вага',
	},
	nav_item4: {
		en: 'Fat percentage',
		ua: 'Процент жиру',
	},
	nav_item5: {
		en: 'Setting',
		ua: 'Налаштування',
	},
	nav_item6: {
		en: 'Exit account',
		ua: 'Вихiд',
	},

	// Dashboard
	dash_title: {
		en: 'Dashboard',
		ua: 'Цифрова панель',
	},
	dash_subtitle: {
		en: 'Life expectancy',
		ua: 'Очікувана тривалість життя',
	},
	dash_year: {
		en: 'Year',
		ua: 'Рiк',
	},
	men1: {
		en: 'men',
		ua: 'чоловiки',
	},
	men2: {
		en: 'men',
		ua: 'чоловiки',
	},
	men3: {
		en: 'men',
		ua: 'чоловiки',
	},
	men4: {
		en: 'men',
		ua: 'чоловiки',
	},
	men5: {
		en: 'men',
		ua: 'чоловiки',
	},
	men6: {
		en: 'men',
		ua: 'чоловiки',
	},
	men7: {
		en: 'men',
		ua: 'чоловiки',
	},
	women1: {
		en: 'women',
		ua: 'жiнки',
	},
	women2: {
		en: 'women',
		ua: 'жiнки',
	},
	women3: {
		en: 'women',
		ua: 'жiнки',
	},
	women4: {
		en: 'women',
		ua: 'жiнки',
	},
	women5: {
		en: 'women',
		ua: 'жiнки',
	},
	women6: {
		en: 'women',
		ua: 'жiнки',
	},
	women7: {
		en: 'women',
		ua: 'жiнки',
	},
	population: {
		en: 'Population of Ukraine',
		ua: 'Населення України',
	},
	residents1: {
		en: 'permanent residents',
		ua: 'постійних жителів',
	},
	residents2: {
		en: 'permanent residents',
		ua: 'постійних жителів',
	},
	male: {
		en: 'Male',
		ua: 'Чоловiк',
	},
	female: {
		en: 'Female',
		ua: 'Жiнка',
	},

	// Calories
	cal_title: {
		en: 'Calorie rate',
		ua: 'Норма калорiй',
	},
	cal_subtitle: {
		en: 'Daily calorie requirement',
		ua: 'Добова потреба в калоріях',
	},
	data: {
		en: 'Physical data',
		ua: 'Фізичні данні',
	},
	height: {
		en: 'height (sm)',
		ua: 'зрiст (см)',
	},
	weight: {
		en: 'weight (kg)',
		ua: 'вага (кг)',
	},
	age: {
		en: 'age',
		ua: 'вiк',
	},
	level: {
		en: 'Activity level',
		ua: 'Рівень активності',
	},
	result: {
		en: 'Result',
		ua: 'Результат',
	},
	low: {
		en: 'Low',
		ua: 'Низький',
	},
	small: {
		en: 'Small',
		ua: 'Помірний',
	},
	medium: {
		en: 'Medium',
		ua: 'Cередній',
	},
	high: {
		en: 'High',
		ua: 'Високий',
	},
	calorie_rate: {
		en: 'Calorie rate:',
		ua: 'Норма калорiй:',
	},
	cal_norm_title: {
		en: 'Calorie rate',
		ua: 'Норма калорiй',
	},
	men: {
		en: 'For Men',
		ua: 'Для чоловіків',
	},
	age_men: {
		en: 'Age',
		ua: 'Вiк',
	},
	active_men: {
		en: 'Activity',
		ua: 'Активність',
	},
	rate_men: {
		en: 'Rate',
		ua: 'Норма',
	},
	women: {
		en: 'For Women',
		ua: 'Для жiнок',
	},
	age_women: {
		en: 'Age',
		ua: 'Вiк',
	},
	active_women: {
		en: 'Activity',
		ua: 'Активність',
	},
	rate_women: {
		en: 'Rate',
		ua: 'Норма',
	},

	// ideal-weight
	idw_title: {
		en: 'Ideal weight',
		ua: 'Ідеальна вага',
	},
	idw_subtitle: {
		en: 'Body Mass Index',
		ua: 'Індекс маси тіла',
	},
	bmi: {
		en: 'BMI',
		ua: 'IМТ',
	},
	etalon: {
		en: 'Ideal weight (kg)',
		ua: 'Оптимальна вага (кг)',
	},
	current_bmi: {
		en: 'Current BMI:',
		ua: 'Поточний IМТ:',
	},
	recommendation: {
		en: 'Recommendation',
		ua: 'Рекомендацiя',
	},
	idw_table_title: {
		en: 'For Men & Women',
		ua: 'Для чоловiкiв та жiнок',
	},
	idw_table: {
		en: 'Table BMI',
		ua: 'Таблиця IМТ',
	},
	idw_title2: {
		en: 'Description',
		ua: 'Пояснення',
	},
	idw_tbl1: {
		en: 'less 16',
		ua: 'менше 16',
	},
	idw_tbl2: {
		en: 'Severe underweight',
		ua: 'Сильний дефіцит маси тіла',
	},
	idw_tbl3: {
		en: 'Underweight',
		ua: 'Дефіцит маси тіла',
	},
	idw_tbl4: {
		en: 'Optimal weight',
		ua: 'Оптимальна вага',
	},
	idw_tbl5: {
		en: 'Overweight',
		ua: 'Зайва вага',
	},
	idw_tbl6: {
		en: 'Obesity I level',
		ua: 'Ожиріння I ступеня',
	},
	idw_tbl7: {
		en: 'Obesity II level',
		ua: 'Ожиріння II ступеня',
	},
	idw_tbl8: {
		en: 'more 40',
		ua: 'бiльш 40',
	},
	idw_tbl9: {
		en: 'Obesity III level',
		ua: 'Ожиріння III ступеня',
	},

	// Fat-percentage
	fat_title: {
		en: 'Fat percentage',
		ua: 'Відсоток жиру',
	},
	fat_subtitle: {
		en: 'Body Fat Calculator',
		ua: 'Калькулятор жиру в організмі',
	},
	optima: {
		en: 'Ideal fat mass (%)',
		ua: 'Ідеальна маса жиру (%)',
	},
	fat_mass: {
		en: 'Fat mass:',
		ua: 'Жирова маса:',
	},
	fat_table: {
		en: 'Body fat rate',
		ua: 'Норма жиру',
	},
	norm_men: {
		en: 'Norm fat, %',
		ua: 'Норма жиру, %',
	},
	less_twenty_men: {
		en: 'less 20',
		ua: 'менше 20',
	},
	more_fifty_men: {
		en: 'more 51',
		ua: 'більше 51',
	},
	norm_women: {
		en: 'Norm fat, %',
		ua: 'Норма жиру, %',
	},
	less_twenty_women: {
		en: 'less 20',
		ua: 'менше 20',
	},
	more_fifty_women: {
		en: 'more 51',
		ua: 'більше 51',
	},
	recomend: {
		en: 'Small % fat! Watch your weight carefully!',
		ua: 'Малий % жиру! Уважно стежте за своєю вагою!',
	},

	// Setting
	set_title: {
		en: 'Setting',
		ua: 'Налаштування',
	},
	set_subtitle1: {
		en: 'Language',
		ua: 'Мова',
	},
	set_subtitle2: {
		en: 'Theme',
		ua: 'Тема',
	},
	set_subtitle3: {
		en: 'Font',
		ua: 'Шрифт',
	},
	set_light: {
		en: 'Light',
		ua: 'Свiтла',
	},
	set_dark: {
		en: 'Dark',
		ua: 'Темна',
	},
};
