import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
//import { contextArr } from './App/db';

import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth';

import App from './App/App';

firebase.initializeApp({
	apiKey: 'AIzaSyCmvKA7VWnQnQJs2RddaMfYBadWuaFy5R4',
	authDomain: 'react-calculator-app-1a60e.firebaseapp.com',
	projectId: 'react-calculator-app-1a60e',
	storageBucket: 'react-calculator-app-1a60e.appspot.com',
	messagingSenderId: '94820249125',
	appId: '1:94820249125:web:f6d45a9535d6bf8c2c59cc',
});

export const Context = createContext(null);

const auth = firebase.auth();
const firestore = firebase.firestore();

ReactDOM.render(
	<Context.Provider
		value={{
			firebase,
			auth,
			firestore,
		}}>
		<App />
	</Context.Provider>,
	document.getElementById('root')
);
