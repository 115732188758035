import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Btn from '../Btn/Btn';
import RangeInput from '../RangeInput/RangeInput';
import Total from '../Total/Total';
import Table from '../Table/Table';
import Burger from '../Burger/Burger';

import './Calories.scss';
import CloseBtn from '../CloseBtn/CloseBtn';

export default function Calories({ lang }) {
	const [gender, setGender] = useState([
		{
			id: 0,
			status: true,
			name: 'male',
			classes: 'lng-men',
			title: 'For Men',
			age: 'lng-age_men',
			active: 'lng-active_men',
			rate: 'lng-rate_men',
			valAge1: '17-40',
			valRate1: '2400-2600',
			valRate2: '2600-2800',
			valRate3: '3000-3200',
			valAge2: '41–60',
			valRate4: '2000-2200',
			valRate5: '2400-2600',
			valRate6: '2600-2800',
			valAge3: '61+',
			valRate7: '2000',
			valRate8: '2200-2400',
			valRate9: '2400-2600',
		},
		{
			id: 1,
			status: false,
			name: 'female',
			classes: 'lng-women',
			title: 'For Women',
			age: 'lng-age_women',
			active: 'lng-active_women',
			rate: 'lng-rate_women',
			valAge1: '17-40',
			valRate1: '1800-2000',
			valRate2: '2000-2200',
			valRate3: '2200-2400',
			valAge2: '41–60',
			valRate4: '1600-1800',
			valRate5: '1800-2000',
			valRate6: '2000-2200',
			valAge3: '61+',
			valRate7: '1600',
			valRate8: '1800',
			valRate9: '2000',
		},
	]);

	const [ranges, setRanges] = useState([
		{
			id: 'height',
			min: 50,
			max: 220,
			label: 'height (sm)',
			lng: 'lng-height',
			value: 135,
		},
		{
			id: 'weight',
			min: 1,
			max: 200,
			label: 'weight (kg)',
			lng: 'lng-weight',
			value: 100,
		},
		{ id: 'age', min: 1, max: 110, label: 'age', lng: 'lng-age', value: 55 },
	]);

	const [isActive, setActive] = useState([
		{ id: 'a0', status: true, name: 'low', ratio: 1.2 },
		{ id: 'a1', status: false, name: 'small', ratio: 1.375 },
		{ id: 'a2', status: false, name: 'medium', ratio: 1.55 },
		{ id: 'a3', status: false, name: 'high', ratio: 1.725 },
	]);

	const [total, setTotal] = useState(0);

	const chooseGender = (id) => {
		setGender(
			gender.map((item) =>
				item.id === id ? { ...item, status: true } : { ...item, status: false }
			)
		);
	};

	const chooseRange = (id, value) => {
		setRanges(
			ranges.map((item) => (item.id === id ? { ...item, value } : item))
		);
	};

	const chooseActive = (id) => {
		setActive(
			isActive.map((item) =>
				item.id === id ? { ...item, status: true } : { ...item, status: false }
			)
		);
	};

	useEffect(() => {
		const sex = gender.find((e) => e.status === true).name;
		const height = ranges[0].value;
		const weight = ranges[1].value;
		const age = ranges[2].value;
		const ratio = isActive.find((e) => e.status === true).ratio;

		sex === 'male'
			? setTotal(
					Math.round((88.36 + 13.4 * weight + 4.8 * height - 5.7 * age) * ratio)
			  )
			: setTotal(
					Math.round((447.6 + 9.2 * weight + 3.1 * height - 4.3 * age) * ratio)
			  );
	}, [gender, isActive, ranges]);

	const location = useLocation();

	useEffect(() => {
		window.location.href = location.pathname + '#' + lang;
	}, [location.pathname, lang]);

	const [showBurger, setShowBurger] = useState(false);

	return (
		<section id='сalories'>
			<div className='wrap df'>
				<div className='left df'>
					<div className='title'>
						<h1 className='lng-cal_title'>Calorie rate</h1>
						<Burger
							style={{ display: 'none' }}
							onClick={() => setShowBurger(!showBurger)}
						/>
					</div>
					<div className='wrapper'>
						<h3 className='lng-cal_subtitle'>Daily calorie requirement</h3>
						<div className='line-choose'>
							<div className='gender df'>
								{gender.map((item) => (
									<Btn
										key={item.id}
										onClick={() => chooseGender(item.id)}
										className={
											item.status
												? `btn lng-${item.name} active`
												: `btn lng-${item.name}`
										}>
										{item.name}
									</Btn>
								))}
							</div>
						</div>
						<div className='line-choose line2'>
							<h4 className='lng-data'>Physical data</h4>
							<div className='ranges df'>
								{ranges.map((item) => (
									<RangeInput
										key={item.id}
										onChange={chooseRange}
										value={item.value}
										{...item}
									/>
								))}
							</div>
						</div>
						<div className='line-choose line3'>
							<h4 className='lng-level'>Activity level</h4>
							<div className='btns df fww'>
								{isActive.map((item) => (
									<Btn
										key={item.id}
										onClick={() => chooseActive(item.id)}
										ratio={item.ratio}
										className={
											item.status
												? `btn lng-${item.name} active`
												: `btn lng-${item.name}`
										}>
										{item.name}
									</Btn>
								))}
							</div>
						</div>
					</div>
					<div className='line-choose'>
						<Total
							title={'Result'}
							subtitle={'Calorie rate:'}
							classSubtitle={'lng-calorie_rate'}
							total={total}
							units={'kcal'}
						/>
					</div>
				</div>

				<div className={'right ' + (showBurger ? 'show' : '')}>
					<h3 className='lng-cal_norm_title'>Calorie rate</h3>
					{gender.map((item) => (
						<Table
							key={item.id}
							id={item.name}
							className={item.status ? 'bw show' : 'bw'}>
							<caption className={item.classes}>{item.title}</caption>
							<thead>
								<tr>
									<th className={item.age}>Age</th>
									<th className={item.active}>Activity</th>
									<th className={item.rate}>Rate</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td rowSpan='3'>{item.valAge1}</td>
									<td>
										<i className='fas fa-battery-quarter'></i>
									</td>
									<td>{item.valRate1}</td>
								</tr>
								<tr>
									<td>
										<i className='fas fa-battery-half'></i>
									</td>
									<td>{item.valRate2}</td>
								</tr>
								<tr>
									<td>
										<i className='fas fa-battery-full'></i>
									</td>
									<td>{item.valRate3}</td>
								</tr>
								<tr>
									<td rowSpan='3'>{item.valAge2}</td>
									<td>
										<i className='fas fa-battery-quarter'></i>
									</td>
									<td>{item.valRate4}</td>
								</tr>
								<tr>
									<td>
										<i className='fas fa-battery-half'></i>
									</td>
									<td>{item.valRate5}</td>
								</tr>
								<tr>
									<td>
										<i className='fas fa-battery-full'></i>
									</td>
									<td>{item.valRate6}</td>
								</tr>
								<tr>
									<td rowSpan='3'>{item.valAge3}</td>
									<td>
										<i className='fas fa-battery-quarter'></i>
									</td>
									<td>{item.valRate7}</td>
								</tr>
								<tr>
									<td>
										<i className='fas fa-battery-half'></i>
									</td>
									<td>{item.valRate8}</td>
								</tr>
								<tr>
									<td>
										<i className='fas fa-battery-full'></i>
									</td>
									<td>{item.valRate9}</td>
								</tr>
							</tbody>
						</Table>
					))}
					<CloseBtn
						style={{ display: 'none' }}
						onClick={() => setShowBurger(!showBurger)}
					/>
				</div>
			</div>
		</section>
	);
}
