/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Btn from '../Btn/Btn';
import RangeInput from '../RangeInput/RangeInput';
import Total from '../Total/Total';
import Optima from '../Optima/Optima';
import RecommendationFatPercentage from '../Recommendation/RecommendationFatPercentage';
import CloseBtn from '../CloseBtn/CloseBtn';
import Table from '../Table/Table';
import Burger from '../Burger/Burger';

import './FatPercentage.scss';

export default function FatPercentage({ lang }) {
	const [gender, setGender] = useState([
		{
			id: 0,
			status: true,
			name: 'male',
			classes: 'lng-men',
			title: 'For Men',
			age: 'lng-age_men',
			norm: 'lng-norm_men',
			lessTwenty: 'lng-less_twenty_men',
			lessTwentyVal1: '9-15',
			twentyFiftyVal1: '17-27',
			moreFifty: 'lng-more_fifty_men',
			moreFiftyVal1: '19-23',
		},
		{
			id: 1,
			status: false,
			name: 'female',
			classes: 'lng-women',
			title: 'For Women',
			age: 'lng-age_women',
			norm: 'lng-norm_women',
			lessTwenty: 'lng-less_twenty_women',
			lessTwentyVal1: '14-21',
			twentyFiftyVal1: '17-27',
			moreFifty: 'lng-more_fifty_women',
			moreFiftyVal1: '20-30',
		},
	]);

	const [ranges, setRanges] = useState([
		{
			id: 'bmi',
			min: 1,
			max: 45,
			label: 'bmi',
			lng: 'lng-bmi',
			value: 23,
		},
		{
			id: 'height',
			min: 50,
			max: 220,
			label: 'height (sm)',
			lng: 'lng-height',
			value: 135,
		},
		{ id: 'age', min: 1, max: 110, label: 'age', lng: 'lng-age', value: 55 },
	]);

	const chooseGender = (id) => {
		setGender(
			gender.map((item) =>
				item.id === id ? { ...item, status: true } : { ...item, status: false }
			)
		);
	};

	const chooseRange = (id, value) => {
		setRanges(
			ranges.map((item) => (item.id === id ? { ...item, value } : item))
		);
	};

	const [total, setTotal] = useState(0);

	const sex = gender.find((e) => e.status === true).name;
	const bmi = ranges[0].value;
	const height = ranges[1].value;
	const age = ranges[2].value;

	useEffect(() => {
		sex === 'male'
			? setTotal(Math.round((0.715 * bmi - 12.1) * (height / 100) ** 2))
			: setTotal(Math.round((0.713 * bmi - 9.74) * (height / 100) ** 2));
	}, [gender, ranges]);

	const location = useLocation();

	useEffect(() => {
		window.location.href = location.pathname + '#' + lang;
	}, [location.pathname, lang]);

	const [showBurger, setShowBurger] = useState(false);

	return (
		<section id='fat-percentage'>
			<div className='wrap df'>
				<div className='left df'>
					<div className='title'>
						<h1 className='lng-fat_title'>Fat percentage</h1>
						<Burger
							style={{ display: 'none' }}
							onClick={() => setShowBurger(!showBurger)}
						/>
					</div>
					<div className='wrapper'>
						<h3 className='lng-fat_subtitle'>Body Fat Calculator</h3>
						<div className='line-choose fat_line1'>
							<div className='gender df'>
								{gender.map((item) => (
									<Btn
										key={item.id}
										onClick={() => chooseGender(item.id)}
										className={
											item.status
												? `btn lng-${item.name} active`
												: `btn lng-${item.name}`
										}>
										{item.name}
									</Btn>
								))}
							</div>
						</div>
						<div className='line-choose fat_line2'>
							<h4 className='lng-fat_data'>Physical data</h4>
							<div className='ranges df'>
								{ranges.map((item) => (
									<RangeInput
										key={item.id}
										onChange={chooseRange}
										value={item.value}
										{...item}
									/>
								))}
							</div>
						</div>
					</div>
					<div className='line-choose'>
						<Total
							title={'Result'}
							subtitle={'Fat mass:'}
							classSubtitle={'lng-fat_mass'}
							total={total}
							units={'%'}>
							<Optima age={age} sex={sex} />
						</Total>
					</div>
					<div className='line-choose'>
						<div id='recommendation'>
							<h4 className='lng-recommendation'>Recommendation</h4>
							<RecommendationFatPercentage
								sex={sex}
								total={total}
								ranges={ranges}
								age={age}
							/>
						</div>
					</div>
				</div>
				<div className={'right ' + (showBurger ? 'show' : '')}>
					<h3 className='lng-fat_table'>Body fat rate</h3>
					{gender.map((item) => (
						<Table
							key={item.id}
							id={item.name}
							className={item.status ? 'bw show' : 'bw'}>
							<caption className={item.classes}>{item.title}</caption>
							<thead>
								<tr>
									<th className={item.age}>Age</th>
									<th className={item.norm}>Norm fat, %</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className={item.lessTwenty}>less 20</td>
									<td>{item.lessTwentyVal1}</td>
								</tr>
								<tr>
									<td>20-50</td>
									<td>{item.twentyFiftyVal1}</td>
								</tr>
								<tr>
									<td className={item.moreFifty}>more 50</td>
									<td>{item.moreFiftyVal1}</td>
								</tr>
							</tbody>
						</Table>
					))}
					<CloseBtn
						style={{ display: 'none' }}
						onClick={() => setShowBurger(!showBurger)}
					/>
				</div>
			</div>
		</section>
	);
}
