import React from 'react';
import Logo from '../Logo/Logo';
import Navbar from '../Navbar/Navbar';
import Author from '../Author/Author';

import './Menu.scss';

class Tabs extends React.Component {
	constructor() {
		super();
		this.state = {
			selected: 0,
			active: 0,
		};
	}

	setTab(index) {
		this.setState({
			selected: index,
			active: index,
		});
	}

	_renderLabels() {
		const { active } = this.state;

		return this.props.children.map((child, index) => (
			<li
				className={index === active ? 'active tab-nav' : 'tab-nav'}
				key={child.props.label}
				onClick={() => {
					this.setTab(index);
				}}>
				<i className={child.props.classNameI}></i>
				<span className={child.props.classNameSpan}>{child.props.label}</span>
			</li>
		));
	}

	_renderContent() {
		return (
			<React.Fragment>
				{this.props.children[this.state.selected]}
			</React.Fragment>
		);
	}

	render() {
		return (
			<React.Fragment>
				<div id='menu'>
					<div className='wrap df'>
						<Logo />
						<ul className='tabs-nav'>{this._renderLabels()}</ul>
						<Navbar />
						<Author />
					</div>
				</div>

				{this._renderContent()}
			</React.Fragment>
		);
	}
}

export default Tabs;
