import React from 'react';

import './LinkOut.scss';

export default function LinkOut(props) {
	return (
		<a id='linkout' href={props.href} target={'_blank'} rel='noreferrer'>
			{props.label}
		</a>
	);
}
