import React from 'react';
import './Optima.scss';

const Optima = (props) => {
	let value;
	if (props.sex === 'male') {
		if (props.age < 20) {
			value = '9-15';
		} else if (props.age > 20 && props.age <= 50) {
			value = '17-27';
		} else if (props.age > 50) {
			value = '19-23';
		}
	} else {
		if (props.age < 20) {
			value = '14-21';
		} else if (props.age > 20 && props.age <= 50) {
			value = '17-27';
		} else if (props.age > 50) {
			value = '20-30';
		}
	}

	return (
		<div id='optima' className='range'>
			<h5 className='lng-optima'>Ideal fat mass (%)</h5>
			<div className='output'>{value}</div>
		</div>
	);
};

export default Optima;
