import React from 'react';
import './Recommendation.scss';

const RecommendationEn = (props) => {
	if (props.ranges[0].value === 176 && props.ranges[1].value === 90) {
		return (
			<div className='wrapper' style={{ background: '#d5e0ee', color: '#fff' }}>
				Overweight
			</div>
		);
	} else {
		if (props.total <= 16) {
			return (
				<div className='wrapper' style={{ background: '#e77070' }}>
					Severe underweight
				</div>
			);
		} else if (props.total > 16 && props.total <= 18.5) {
			return (
				<div className='wrapper' style={{ background: '#ebc82e' }}>
					Underweight
				</div>
			);
		} else if (props.total > 18.5 && props.total <= 25) {
			return (
				<div className='wrapper' style={{ background: '#7ae770' }}>
					Optimal weight
				</div>
			);
		} else if (props.total > 25 && props.total <= 30) {
			return (
				<div className='wrapper' style={{ background: '#ebc82e' }}>
					Overweight
				</div>
			);
		} else if (props.total > 30 && props.total <= 35) {
			return (
				<div className='wrapper' style={{ background: '#ebc82e' }}>
					Obesity I level
				</div>
			);
		} else if (props.total > 35 && props.total <= 40) {
			return (
				<div className='wrapper' style={{ background: '#e77070' }}>
					Obesity II level
				</div>
			);
		}
		return (
			<div className='wrapper' style={{ background: '#852d80' }}>
				Obesity III level
			</div>
		);
	}
};

export default RecommendationEn;
